import { TRouter } from '@/types/router';
import { HighlightOutlined, UnorderedListOutlined } from '@ant-design/icons-vue';
import VideoOutlined from '@/components/icon/VideoOutlined.vue';

export default {
  path: '/video',
  name: 'Video',
  component: () => import('@/pages/video/index.vue'),
  redirect: '/video/videoList',
  meta: {
    sort: 10,
    icon: VideoOutlined,
    navigation: 'router.video',
    requireAuth: true
  },
  beforeEnter: async () => {
    /**
     * 这里定义控制台单页应用进入前的导航守卫逻辑
     */
  },
  children: [
    {
      path: '/video/editor-pro',
      name: 'EditorPro',
      component: () => import('@/pages/video/editorPro.vue'),
      meta: {
        icon: HighlightOutlined
      }
    },
    {
      path: '/video/videoList',
      name: 'VideoList',
      component: () => import('@/pages/video/videoList.vue'),
      meta: {
        icon: UnorderedListOutlined,
        navigation: 'router.dubbingList'
      }
    }
  ]
} as TRouter;
