<template>
  <section class="bottom-content">
    <a-menu class="bottom-box" mode="inline" :selectedKeys="[router.currentRoute.value.path]">
      <a-menu-item key="/contact" @click="router.push('/contact')">
        <template #icon> <MessageOutlined /> </template>

        {{ t('router.feedback') }}
      </a-menu-item>
      <a-menu-item key="/notification" @click="router.push('/notification')">
        <template #icon> <BellOutlined /> </template>
        {{ t('router.notification') }}
      </a-menu-item>
      <a-menu-item key="/user/profile" @click="router.push('/user')">
        <template #icon> <UserOutlined /> </template>
        {{ useUser.user.email }}
      </a-menu-item>
      <a-menu-item key="setting">
        <template #icon> <GlobalOutlined /> </template>
        <a-select v-model:value="useEvent.event.lang" :bordered="false">
          <a-select-option value="en">EN</a-select-option>
          <a-select-option value="zh">中文</a-select-option>
        </a-select>
      </a-menu-item>
    </a-menu>
  </section>
</template>

<script setup lang="tsx" name="AsideBottom">
import { UserOutlined, BellOutlined, GlobalOutlined, MessageOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { userStore } from '@/store/user';
import { eventStore } from '@/store/event';
import i18n from '@/i18n';

const { t } = i18n.global;
const useUser = userStore();
const useEvent = eventStore();
const router = useRouter();
</script>

<style lang="less" scoped>
.bottom-content {
  .bottom-box {
    border-right: none;

    :global(.ant-menu-submenu-popup) {
      display: none;
    }
  }
}
</style>
